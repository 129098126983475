import React from 'react';
import { PageProps } from 'gatsby';
import { Locale } from 'features/localization';
import { NewsletterSubscriptionsPage } from 'features/newsletter-subscriptions';
import { ExternistNode } from 'types/externist';

interface DataType {
  allExternistsJson: {
    nodes: ExternistNode[];
  };
}

function Page({ pageContext: { locale } }: PageProps<DataType, { locale: Locale }>) {
  return <NewsletterSubscriptionsPage locale={locale} />;
}

export default Page;
