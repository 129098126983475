import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, navigate } from '@reach/router';
import {
  Container,
  Group,
  Checkbox,
  Text,
  Title,
  Button,
  Space,
  Box,
  LoadingOverlay,
  Dialog,
} from '@aviationexam/core';
import { Controller, useForm } from 'react-hook-form';
import { publicUserNotificationClient } from 'utils/api';
import { Locale, usePublicTranslation } from 'features/localization';
import { SEO } from 'components';
import { useDataLayer } from 'hooks';
import { NewsletterCategoryOutput } from '@aviationexam/api-client-public';
import useStyles from './NewsletterSubscriptions.styles';

interface NewsletterSubscriptionsPageProps {
  locale: Locale;
}

interface NotificationProps {
  open: boolean;
  type?: 'error' | 'success';
}

export function NewsletterSubscriptionsPage({ locale }: NewsletterSubscriptionsPageProps) {
  const { t } = usePublicTranslation();
  const location = useLocation();
  // const language = locale === 'en' ? 'En' : 'De';
  const { handleSubmit, getValues, setValue, control } = useForm();
  const credentials = location.pathname.split('/').slice(-2);
  const [isLoading, setIsLoading] = useState(true);
  const [dialog, setDialog] = useState<NotificationProps>({ open: false });
  const [newsletterCategories, setNewsletterCategories] = useState<NewsletterCategoryOutput[]>();
  const { classes } = useStyles();
  // const { alternateLinks } = useAlternateLinks(
  //   `${NEWSLETTER_SUBSCRIPTION_PAGE}/${credentials[0]}/${credentials[1]}`,
  //   locale
  // );

  const fetchNewsletterCategories = useCallback(async (userId, secret) => {
    try {
      const categories = await publicUserNotificationClient
        .withLanguage('De')
        .getNewsletterCategories(userId, secret);
      setNewsletterCategories(categories);
      setIsLoading(false);
    } catch (error) {
      setDialog({ open: true, type: 'error' });
    }
  }, []);

  const onSubmit = useCallback(async data => {
    setIsLoading(true);
    setDialog({ open: false });
    let formattedData = [];
    try {
      Object.keys(data).forEach(key => {
        formattedData = [...formattedData, { categoryId: key, subscribed: data[key] }];
      });
      publicUserNotificationClient.setNewsletterCategories(
        parseInt(credentials[0], 10),
        credentials[1],
        { newsletterCategories: formattedData }
      );
      setDialog({ open: true, type: 'success' });
      setIsLoading(false);
    } catch (error) {
      setDialog({ open: true, type: 'error' });
      fetchNewsletterCategories(parseInt(credentials[0], 10), credentials[1]);
    }
  }, []);

  useEffect(() => {
    if (credentials[0] && credentials[1] && /^\d*$/.test(credentials[0]) && credentials[0] !== '') {
      fetchNewsletterCategories(parseInt(credentials[0], 10), credentials[1]);
    } else {
      navigate('/app');
    }
  }, []);

  return (
    <>
      <SEO
        title={t('newsletterSubscriptionsPage.title')}
        description={t('site.description')}
        lang={locale}
        meta={[{ name: 'robots', content: 'noindex' }]}
      >
        {useDataLayer({
          web: { lang: locale },
          page: {
            type: 'other',
            section: 'other',
          },
        })}
        {/* {alternateLinks} */}
      </SEO>
      <Container size="xs">
        <Group direction="column" spacing="lg" grow>
          <LoadingOverlay visible={isLoading} />
          <Space h="xs" />
          <Title align="center" order={2} className={classes.subtitle}>
            {t('newsletterSubscriptionsPage.title')}
          </Title>
          <Title align="center" order={5}>
            {t('newsletterSubscriptionsPage.heading')}
          </Title>
          {isLoading && !newsletterCategories && (
            <>
              <Text color="dimmed">{t('newsletterSubscriptionsPage.loading')}</Text>
              <Space h="xl" />
            </>
          )}
          {newsletterCategories && (
            <form onSubmit={handleSubmit(onSubmit)}>
              {newsletterCategories.map(item => (
                <Box
                  key={item.categoryId}
                  mb="md"
                  style={{ display: 'flex', alignItems: 'flex-start' }}
                >
                  <Box style={{ margin: '3px 16px 0 0' }}>
                    <Controller
                      control={control}
                      name={`${item.categoryId}`}
                      rules={{ required: !item.optional }}
                      defaultValue={item.subscribed}
                      render={({ field: { ref, onChange, value, ...rest } }) => (
                        <Checkbox
                          ref={ref}
                          checked={value}
                          disabled={!item.optional}
                          onChange={onChange}
                          {...rest}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Text
                      weight={600}
                      onClick={() =>
                        item.optional &&
                        setValue(`${item.categoryId}`, !getValues(`${item.categoryId}`))
                      }
                      style={{
                        userSelect: 'none',
                        cursor: item.optional ? 'pointer' : 'not-allowed',
                      }}
                    >
                      {item.notificationCategory.name}
                    </Text>
                    <Text color="dimmed" size="sm">
                      {item.notificationCategory.description}
                    </Text>
                  </Box>
                </Box>
              ))}
              <Space h="sm" />
              <Group position="center">
                <Button type="submit">{t('newsletterSubscriptionsPage.submit')}</Button>
              </Group>
              <Space h="xl" />
              <Space h="xl" />
            </form>
          )}
          <Dialog
            opened
            withCloseButton
            onClose={() => setDialog({ open: false })}
            size="lg"
            radius="md"
          >
            <Text size="sm" style={{ marginBottom: 10 }} weight={500}>
              {dialog.type === 'error'
                ? t('newsletterSubscriptionsPage.submitError')
                : t('newsletterSubscriptionsPage.submitSuccess')}
            </Text>
          </Dialog>
        </Group>
      </Container>
    </>
  );
}
